var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p20"
  }, [_c("el-form", {
    ref: "form",
    staticClass: "w-70",
    attrs: {
      model: _vm.form,
      "label-width": "120px",
      "label-suffix": "："
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "原始密码"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "请输入原始密码"
    },
    model: {
      value: _vm.form.opassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "opassword", $$v);
      },
      expression: "form.opassword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "密码"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "请输入新密码"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "重复密码"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "请再次输入密码"
    },
    model: {
      value: _vm.form.rpassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rpassword", $$v);
      },
      expression: "form.rpassword"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.updateData();
      }
    }
  }, [_vm._v("保存")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };