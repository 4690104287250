import "core-js/modules/es.array.push.js";
export default {
  name: 'IndexInfo',
  data() {
    return {
      form: {
        opassword: '',
        password: '',
        rpassword: ''
      }
    };
  },
  methods: {
    updateData() {
      if (this.form.password !== this.form.rpassword) {
        this.$message.error('两次密码不一致');
        return;
      }
      if (this.form.password.length > 0 && this.form.password.length < 6) {
        this.$message.error('密码最少6位长度');
        return;
      }
      this.$w_fun.post('index/info', this.form).then(response => {
        this.$router.push('/');
      });
    }
  }
};